<template>
  <section class="m-record m-scroll">

    <!-- 头部导航 -->
    <m-header title="浏览记录">
      <img src="@/assets/record/icon_qingchu@2x.png" slot="right" alt="clear" @click="onClear">
    </m-header>


    <!-- 关注列表 -->
    <van-list  v-model="loading" :immediate-check="false"	  :finished="finished" finished-text="暂无数据" @load="onLoad" class="list">
     
      <div class="">
        <div class="item-title m-padding">今天</div>
        <van-swipe-cell v-for="(it,index) in data" :key="index">
            <div class="item-wrap" >
              <div class="avatar"><img src="@/assets/fork-imgs/15674258757059uqh2m.png" alt="img"></div>
              <div class="info van-hairline--bottom">
                <p>可爱美少女翅膀粉色便携迷你手机美颜补光灯</p>
                <div>
                  <div>
                    <p><span>$</span><span>17.99</span></p>
                    <p>$21.99</p>
                  </div>
                  <img src="@/assets/index/iocn-gouwu@2x(1).png" alt="">
                </div>
              </div>
            </div>

          <template #right>
            <span class="delete">
              <img src="@/assets/record/icon_delte@2x.png" alt="">
            </span>
          </template>
        </van-swipe-cell>

      </div>
      

    </van-list>
  


    

  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      data:[{checked:false,person:1},{checked:false,person:1.1},{checked:false,person:1.2},{checked:false,person:1.3},{checked:false,person:1.},{checked:false,person:1.4},],
      loading:false,
      finished:true,
    }
  },

  computed: {
    checkedAll: {
      set(){
        return false
      },
      get(){
        let bol = false
        this.data.forEach(e => {
          if(!e.checked){
            bol = true
          }
        })
        if(bol){
          return false
        }else{
          return true
        }
      }
    },
  },

  methods:{
    // 加载列表
    onLoad(){
      console.log('加载')
      let timer = setTimeout( ()=> {
        // this.data += 4
        this.loading = false
        if(this.data >= 12){
          this.finished = true
          clearTimeout(timer)
        }
      },1500)
      
    },
    // 点击头部编辑
    onClear(){
      this.$dialog.confirm({
        title: '清除所有',
        message: '确定要清除所有游览记录吗？',
      }) .then(() => {
        this.data = []
      })
      .catch(() => {
        // on cancel
      });
    },

   

  
   
  },
}
</script>

<style lang="less" scoped>
@import './record.less';
</style>